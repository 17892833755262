<template>
    <div style="padding-top: 20px;">
        <div class="addr-header">
            <img class="back-arrow" src="/images/Exchange/Arrowhead_Left.png" @click="exit"/>
            <div class="addr-title">我的收货地址</div>
        </div>
        <div class="addr-list">
            <div class="addr-item add-addr">
                <div style="width: 90%;">
                    <router-link to="/Exchange/AddAddr">
                        <div class="add-hint">
                            添加收货地址
                        </div>
                    </router-link>
                </div>
                <img class="add-icon" src="/images/Exchange/Plus.png" />
            </div>
            <div v-for="(addr, index) in addrs" :key="index">
                <div class="addr-item">
                    <div class="addr-checkbox">
                        <img class="checkbox-img" :src="addr.isDefault == 1 ? '/images/Exchange/Checked.png' : '/images/Exchange/Unchecked.png'" />
                    </div>
                    <div class="addr-detail">
                        <div class="addr-name">
                            {{ addr.name }}
                            <img v-if="addr.isDefault"  class="default-icon-img" src="/images/Exchange/default_icon.png" />
                        </div>
                        <div class="addr-phone">
                            {{ addr.phone }}
                        </div>
                        <div class="addr-loc">
                            {{ addr.province }} {{ addr.city }} {{ addr.region }} {{ addr.detail }}
                        </div>
                    </div>
                    <div class="addr-editbox">
                        <img class="edit-img" src="/images/Exchange/Edit.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Notify } from 'vant';
    import { getUserAddr } from "@/api/Exchange";
    export default {
        name: "AddrManage",
        data() {
            return {
                addrs: [],
            };
        },
        methods: {
          exit(){
            this.$router.go(-1)
          },
            async initData() {
                try {
                    let info = this.$store.state.user.info;
                    let {errorCode, payload} = await getUserAddr(info.id);
                    console.log(payload);
                    if (errorCode == 200) {
                        this.addrs = payload
                    } else {
                        Notify({type: "danger", message:'获取用户地址失败'});
                    }
                } catch (err) {
                    console.log(err);
                    Notify({type: "danger", message:'获取用户地址失败'});
                }
            },
        },
        mounted() {
            this.initData();
        }
    }
</script>

<style scoped>
    .addr-header {
        text-align: center;
        position: relative;
        margin: 0 auto 10px auto;
    }

    .back-arrow {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
    }

    .addr-title {
        font-size: 18px;
        color: #fff;
        font-weight: bold;
    }

    .addr-list {
        width: 90%;
        margin: 0 auto;
        height: calc(100vh - 55px);
        overflow: scroll;
    }

    .addr-item {
        display: flex;
        background: #21242d;
        border-radius: 20px;
        min-height: 100px;
        box-sizing: border-box;
        padding: 15px 20px;
        margin: 15px auto;
    }

    .checkbox-img {
        width: 20px;
    }

    .edit-img {
        width: 25px;
    }

    .addr-detail {
        color: white;
        width: 80%
    }

    .addr-editbox {
        width: 10%
    }

    .addr-checkbox {
        width: 50px
    }

    .addr-name {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 17px;
        line-height: 17px;
        margin-bottom: 10px;
    }

    .addr-phone {
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 10px;
    }

    .addr-loc {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .default-icon-img {
        width: 40px;
        margin-left: 10px;
    }

    .add-addr {
        display: flex;
        align-items: center;
    }

    .add-icon {
        width: 25px;
    }

    .add-hint {
        color: white;
        font-size: 17px;
        font-weight: 800;
    }
</style>